<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 22C10.8667 22 9.56667 21.7373 8.35 21.212C7.13333 20.6873 6.075 19.975 5.175 19.075C4.275 18.175 3.56267 17.1167 3.038 15.9C2.51267 14.6833 2.25 13.3833 2.25 12C2.25 10.6167 2.51267 9.31667 3.038 8.1C3.56267 6.88333 4.275 5.825 5.175 4.925C6.075 4.025 7.13333 3.31233 8.35 2.787C9.56667 2.26233 10.8667 2 12.25 2C13.6333 2 14.9333 2.26233 16.15 2.787C17.3667 3.31233 18.425 4.025 19.325 4.925C20.225 5.825 20.9373 6.88333 21.462 8.1C21.9873 9.31667 22.25 10.6167 22.25 12C22.25 13.3833 21.9873 14.6833 21.462 15.9C20.9373 17.1167 20.225 18.175 19.325 19.075C18.425 19.975 17.3667 20.6873 16.15 21.212C14.9333 21.7373 13.6333 22 12.25 22ZM12.25 10C12.8 10 13.271 9.804 13.663 9.412C14.0543 9.02067 14.25 8.55 14.25 8C14.25 7.45 14.0543 6.97933 13.663 6.588C13.271 6.196 12.8 6 12.25 6C11.7 6 11.2293 6.196 10.838 6.588C10.446 6.97933 10.25 7.45 10.25 8C10.25 8.55 10.446 9.02067 10.838 9.412C11.2293 9.804 11.7 10 12.25 10ZM8.25 16C8.8 16 9.271 15.804 9.663 15.412C10.0543 15.0207 10.25 14.55 10.25 14C10.25 13.45 10.0543 12.979 9.663 12.587C9.271 12.1957 8.8 12 8.25 12C7.7 12 7.22933 12.1957 6.838 12.587C6.446 12.979 6.25 13.45 6.25 14C6.25 14.55 6.446 15.0207 6.838 15.412C7.22933 15.804 7.7 16 8.25 16ZM16.25 16C16.8 16 17.271 15.804 17.663 15.412C18.0543 15.0207 18.25 14.55 18.25 14C18.25 13.45 18.0543 12.979 17.663 12.587C17.271 12.1957 16.8 12 16.25 12C15.7 12 15.2293 12.1957 14.838 12.587C14.446 12.979 14.25 13.45 14.25 14C14.25 14.55 14.446 15.0207 14.838 15.412C15.2293 15.804 15.7 16 16.25 16Z"
      fill="#365FA3"
    />
  </svg>
</template>
